import React, { useState } from "react";
import {
  FaBookJournalWhills,
  FaCube,
  FaDochub,
  FaFaceSmile,
  FaList,
  FaMagnifyingGlass,
  FaSatellite,
  FaTeeth,
} from "react-icons/fa6";
import { FaMapMarked } from "react-icons/fa";
import { GrDocumentPdf } from "react-icons/gr";
import MarkdownRenderer from "react-markdown-renderer";

export const AISolutions = () => {
  const [selectedSolutionIndex, setSelectedSolutionIndex] = useState(0);

  const solutions = [
    {
      title: "Automatic Report Generation",
      icon: <GrDocumentPdf size={18} className="mt-0" />,
      description:
        "**Writing detailed reports** from research can be time-consuming as it involves filtering, synthesizing, summarizing, and presenting information in a specific style. Modern AI models excel at drafting these reports. These models can **automatically integrate and reason over data** from various sources, generating initial drafts efficiently. **Significant hours can be saved** annually by automatically generating the drafts.",
      img: "report.png",
    },
    {
      title: "Automatic Assessment & Scoring",
      icon: <FaMagnifyingGlass size={18} className="mt-0" />,
      description:
        "**Assessments**, scores, and certifications provide valuable insights, comparisons, and evaluations of projects or companies. Many of these scores are derived from textual data. Modern AI models can **efficiently assess textual data and assign scores** using predefined criteria. This automated scoring process enhances transparency, provides deeper insights, and reduces the manual effort required for assessments.",
      img: "assessment.png",
    },
    {
      title: "GPT with Company's Knowledge Base",
      icon: <FaBookJournalWhills size={18} className="mt-0" />,
      description:
        "Consulting companies accumulate **vast amounts of knowledge and documentation**, making it difficult to **access all relevant information** for a specific theme. Enhancing GPT models with explicit company knowledge can significantly improve their performance. This process, known as Retrieval-Augmented Generation (RAG), involves integrating relevant knowledge from the company's database into the GPT model before generating responses. Several companies use RAG to create **internal GPT systems that leverage their proprietary knowledge** without external data sharing or training by OpenAI.",
      img: "internal_gpt.png",
    },
    {
      title: "Cross-Project Client Insights",
      icon: <FaFaceSmile size={16} className="mt-1" />,
      description:
        "Consulting companies often handle numerous clients and projects, making it challenging to **monitor all communications**. However, tracking client satisfaction for each project, consultant, and over time is crucial for strategic decisions and project management. Current AI language models can **classify sentiment, directness, clarity, and other textual factors in client communications**. When combined with metadata, these models provide valuable **insights into project challenges**, client frustrations, and satisfaction levels per consultant or client.",
      img: "sentiment.png",
    },
    {
      title: "Smart Data Collection",
      icon: <FaList size={12} className="mt-1" />,
      description:
        "Relevant data for a case often exists online but is scattered across various websites with different layouts. Crawlers have long been able to retrieve HTML content from these sites. However, extracting **clean and useful data** from diverse HTML formats was challenging. Recent advancements in Large Language Models (LLMs) have overcome this hurdle. LLMs can understand and extract **relevant features like names, prices, and descriptions** from HTML **regardless of format differences**. Combining crawlers with LLMs enables comprehensive data extraction from the web.",
      img: "crawler.png",
    },
    {
      title: "Orthodontic Image Classification",
      icon: <FaTeeth size={18} className="mt-0" />,
      description:
        "In orthodontics, analyzing and classifying medical images is crucial for diagnosis and treatment planning. AI models, particularly in image classification, can **automatically identify and categorize various orthodontic conditions** from X-rays, photographs, and other imaging modalities. This technology **enhances diagnostic accuracy, speeds up the treatment planning process**, and allows orthodontists to focus more on patient care. Implementing AI-driven image classification in orthodontics ensures **consistent and precise identification of conditions**, leading to better patient outcomes.",
      img: "rontgen.png",
    },
    {
      title: "Orthodontic 3D Image Classification",
      icon: <FaCube size={18} className="mt-0" />,
      description:
        "Orthodontic treatment often involves the use of 3D imaging to get a comprehensive view of a patient's dental structure. AI models for 3D image classification can **analyze and classify 3D orthodontic images** such as CT scans and 3D dental models. These models can **detect anomalies, assess dental arch relationships, and plan surgical interventions** with high precision. This technology **improves the accuracy of diagnoses and treatment plans**, ensuring personalized and effective patient care. Integrating AI into 3D image classification revolutionizes orthodontic diagnostics and treatment planning.",
      img: "3d.png",
    },
    {
      title: "Satellite Data Classification",
      icon: <FaMapMarked size={18} />,
      description:
        "Agricultural monitoring and management benefit greatly from satellite data, which provides comprehensive insights into plant growth and crop yields. AI models can **classify different spectral bands of satellite imagery** to monitor plant health, soil conditions, and water availability. By analyzing these bands, AI can **predict crop yields, detect early signs of disease, and optimize resource usage**. This technology enhances precision agriculture, enabling farmers to make informed decisions and improve productivity. Integrating AI-driven satellite data classification ensures sustainable and efficient agricultural practices.",
      img: "sattelite.png",
    },
  ];
  return (
    <div className="md:my-16  my-4 p-2 md:p-0 max-w-4xl mx-auto md:h-128">
      <h2 className="z-50 mt-8 md:mt-32 text-gray-700 mx-auto text-lg md:text-2xl text-start font-bold  text-center">
        What can AI do for my business?
      </h2>
      <div className="flex gap-1 md:gap-16 justify-between items-start mt-4 md:mt-8">
        <div className="md:w-52 flex flex-col gap-1 text-sm text-gray-400">
          <div
            className={` text-lg font-bold text-transparent bg-clip-text bg-gradient-to-br from-purple-500 to-green-400
            `}
          >
            SOLUTIONS
          </div>
          {solutions.map((item, idx) => (
            <div
              className={`cursor-pointer flex items-start gap-1 md:gap-2 line-clamp-2  ${
                idx == selectedSolutionIndex && "text-purple-400 "
              }`}
              onClick={() => {
                setSelectedSolutionIndex(idx);
              }}
            >
              {item.icon}
              <p
                className={`line-clamp-2  ${
                  idx == selectedSolutionIndex &&
                  " font-bold text-transparent bg-clip-text bg-gradient-to-br from-purple-500 to-green-400 "
                }`}
              >
                {item.title}
              </p>
            </div>
          ))}
        </div>
        <div className="md:w-4/5 w-full flex items-center gap-8">
          <div className="md:w-3/5 w-full flex flex-col gap-2">
            <h3 className="font-bold flex  text-transparent bg-clip-text bg-gradient-to-br from-purple-500 to-green-400 text-xl md:text-3xl">
              {/* {solutions[selectedSolutionIndex].icon}{" "} */}
              {solutions[selectedSolutionIndex].title}
            </h3>
            <MarkdownRenderer
              markdown={solutions[selectedSolutionIndex].description}
              className="text-gray-500 text-sm md:text-md"
            />
          </div>
          <div className="md:w-2/5">
            <img
              src={
                process.env.PUBLIC_URL +
                "/imgs/" +
                solutions[selectedSolutionIndex].img
              }
              className="hidden md:block rounded shadow-md w-full object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
