import React from "react";
import { BsHospital } from "react-icons/bs";
import { FaBuilding, FaBuildingNgo, FaComputer } from "react-icons/fa6";
import { Book, Report, Tractor } from "tabler-icons-react";
import { GoLaw } from "react-icons/go";

export const References = () => {
  return (
    <div className="my-16 p-2 md:p-0 max-w-2xl mx-auto">
      <h2 className="z-50 text-gray-700 mx-auto text-2xl text-start font-bold  text-center">
        References
      </h2>
      <h2 className="z-50 mx-auto mt-8 flex justify-center gap-1 md:gap-2 mx-auto text-lg md:text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-br from-cyan-600 to-green-400 text-center flex-wrap">
        <p className="text-gray-500"> We have experience in </p>3
        <p className="text-gray-500"> countries</p>
      </h2>
      <div className="flex justify-around mt-4">
        <img
          src={process.env.PUBLIC_URL + "/imgs/Flag_of_Brazil.svg"}
          className="w-16"
          alt="Flag of the Netherlands"
        />{" "}
        <img
          src={process.env.PUBLIC_URL + "/imgs/Flag_of_the_Netherlands.svg"}
          className="w-16"
          alt="Flag of the Netherlands"
        />
        <img
          src={process.env.PUBLIC_URL + "/imgs/Flag_of_Belgium.svg"}
          className="w-14"
          alt="Flag of the Netherlands"
        />
      </div>
      <h2 className="z-50 mx-auto mt-8 flex justify-center gap-1 md:gap-2 mx-auto text-lg md:text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-br from-cyan-600 to-green-400 text-center flex-wrap">
        <p className="text-gray-500"> Across </p>6
        <p className="text-gray-500"> industries</p>
      </h2>
      <div className="flex justify-around mt-4 flex-wrap">
        <div className="flex text-gray-500 font-bold flex-col gap-2 items-center">
          <Report size={32} />
          Consulting
        </div>

        <div className="flex text-gray-500 font-bold flex-col gap-2 items-center">
          <FaComputer size={32} />
          Technology/IT
        </div>
        <div className="flex text-gray-500 font-bold flex-col gap-2 items-center">
          <BsHospital size={32} />
          Medical
        </div>
        <div className="flex text-gray-500 font-bold flex-col gap-2 items-center">
          <Tractor size={32} />
          Agriculture
        </div>
        <div className="flex text-gray-500 font-bold flex-col gap-2 items-center">
          <Book size={32} />
          Publishing
        </div>
        <div className="flex text-gray-500 font-bold flex-col gap-2 items-center">
          <FaBuildingNgo size={32} />
          NGO
        </div>
      </div>
    </div>
  );
};
