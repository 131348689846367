import React from "react";

import { FaArrowRight, FaThumbsUp, FaLightbulb } from "react-icons/fa6";
import { TfiWrite } from "react-icons/tfi";
import { AiOutlineCustomerService } from "react-icons/ai";
import { HiOutlineDocumentCheck, HiSparkles } from "react-icons/hi2";
import { BsGraphUpArrow } from "react-icons/bs";
import { CgWebsite } from "react-icons/cg";

export const Automated = () => {
  return (
    <div className="my-16 mt-32 p-2 md:p-0 max-w-2xl mx-auto">
      <h2 className="z-50 text-gray-700 mx-auto text-2xl text-start font-bold  text-center">
        Some processes we have automated
      </h2>
      <ul className="text-lg text-gray-500 gap-8 mt-8 flex flex-col">
        <li className="flex flex-col gap-2 items-start pb-1 border-b">
          <div className="flex gap-2 items-center">
            <TfiWrite
              className="fill-emerald-500 stroke-emerald-600"
              size={32}
            />
            <p className="font-bold text-emerald-500">Read info</p>
          </div>
          <p className="text-sm text-gray-500">
            Utilize AI to read and interpret handwritten content from documents,
            improving data entry and archival processes.
          </p>
        </li>
        <li className="flex flex-col gap-2 items-start pb-1 border-b">
          <div className="flex gap-2 items-center">
            <AiOutlineCustomerService
              className="fill-emerald-500 stroke-emerald-600"
              size={32}
            />
            <p className="font-bold text-emerald-500">
              Handle customer service
            </p>
          </div>
          <p className="text-sm text-gray-500">
            Leverage AI to manage customer interactions, offering automated
            responses and support 24/7.
          </p>
        </li>
        <li className="flex flex-col gap-2 items-start pb-1 border-b">
          <div className="flex gap-2 items-center">
            <FaThumbsUp
              className="fill-emerald-500 stroke-emerald-600"
              size={32}
            />
            <p className="font-bold text-emerald-500">Classify texts</p>
          </div>
          <p className="text-sm text-gray-500">
            Employ AI to categorize text by topic, quality, and relevance,
            enhancing content management and prioritization.
          </p>
        </li>
        <li className="flex flex-col gap-2 items-start pb-1 border-b">
          <div className="flex gap-2 items-center">
            <HiOutlineDocumentCheck
              className="fill-white stroke-emerald-500"
              size={32}
            />
            <p className="font-bold text-emerald-500">
              Compare and ask questions
            </p>
          </div>
          <p className="text-sm text-gray-500">
            Use AI to compare documents and query databases, facilitating
            in-depth analysis and information retrieval.
          </p>
        </li>
        <li className="flex flex-col gap-2 items-start pb-1 border-b">
          <div className="flex gap-2 items-center">
            <BsGraphUpArrow
              className="fill-emerald-500 stroke-emerald-600"
              size={30}
            />
            <p className="font-bold text-emerald-500">Forecast</p>
          </div>
          <p className="text-sm text-gray-500">
            Deploy AI to predict future sales trends and workloads, aiding in
            strategic planning and resource allocation.
          </p>
        </li>
        <li className="flex flex-col gap-2 items-start">
          <div className="flex font-bold gap-2 items-center">
            <HiSparkles
              className="fill-emerald-500 stroke-emerald-600"
              size={32}
            />
            <p>Having your own idea?</p>
          </div>
          <p className="text-gray-500">
            Not sure about what it would look like in practice?
          </p>
          <p className="text-sm text-gray-500 flex flex-wrap gap-1">
            We help you develop{" "}
            <p className="font-bold text-emerald-500">abstract ideas</p> into
            optional{" "}
            <p className="font-bold text-emerald-500">
              AI architectures & project plans.
            </p>
          </p>
          <div className="flex text-sm gap-1">
            {" "}
            <p className="font-bold"> Casual</p> and{" "}
            <p className="font-bold"> free of any obligations!</p>
          </div>
          <div className="flex justify-center gap-8 w-full font-bold mt-16">
            <div className="flex flex-col items-center">
              {" "}
              <FaLightbulb size={46} className=" z-10" />
              {/* <FaQuestion size={46} className="opacity-75 -ml-6" /> */}
              <p>Abstract Idea</p>
            </div>
            <FaArrowRight size={46} className="text-gray-500" />
            <div className="flex flex-col items-center fill-emerald-500 stroke-emerald-600 text-emerald-500">
              {" "}
              <CgWebsite size={46} className="" />
              {/* <FaQuestion size={46} className="opacity-75 -ml-6" /> */}
              <p>AI Architecture & Solution</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};
