import React, { useEffect, useState } from "react";
import { NavBar } from "../NavBar";
import { Element, scroller } from "react-scroll";
import { Footer } from "./Footer";

export const PrivacyPolicy = (props) => {
  //Scroll to section
  useEffect(() => {
    const section = props.match.params.section;
    if (section) {
      scroller.scrollTo(section, {
        delay: 400,
        duration: 1000,
        smooth: true,
      });
    }
  });

  return (
    <div className="bg-slate-50 flex flex-col min-h-screen">
      <NavBar />
      <div className="mx-auto max-w-xl p-4 mb-32">
        <h1 className="z-50 mt-8 mx-auto text-2xl md:text-6xl font-bold text-transparent bg-clip-text bg-gradient-to-br from-cyan-600 to-green-400 text-center">
          {"Privacy Policy".toUpperCase()}
        </h1>
        <div className="mt-8 text-lg text-gray-800 space-y-6">
          <p>
            Welcome to PitchAI's privacy policy. At PitchAI, accessible from{" "}
            <a href="https://pitchai.net" className="text-blue-500">
              https://pitchai.net
            </a>
            , one of our main priorities is the privacy of our visitors. This
            privacy policy document contains types of information that is
            collected and recorded by PitchAI and how we use it.
          </p>

          <h2 className="text-xl font-semibold">Information We Collect</h2>
          <p>
            PitchAI collects and processes only the information necessary for us
            to provide our services and to improve user experience. The primary
            data collected are through Google Analytics, which may include:
          </p>
          <ul className="list-disc list-inside">
            <li>
              <strong>Device Information:</strong> Information about the device
              you use to access our website, such as hardware model, operating
              system version, and mobile network information.
            </li>
            <li>
              <strong>Log Information:</strong> Details of how you used our
              service, such as your search queries, IP address, browser type,
              and language, the date and time of your request, and referral URL.
            </li>
            <li>
              <strong>Location Information:</strong> Information about your
              actual location that may be determined through IP address.
            </li>
          </ul>

          <h2 className="text-xl font-semibold">How We Use Your Information</h2>
          <p>
            The information we collect from you may be used in one of the
            following ways:
          </p>
          <ul className="list-disc list-inside">
            <li>
              <strong>To Improve Our Website:</strong> We continually strive to
              improve our website offerings based on the information and
              feedback we receive from you.
            </li>
            <li>
              <strong>To Personalize User Experience:</strong> Your information
              helps us to better respond to your individual needs and improve
              the user experience.
            </li>
            <li>
              <strong>To Analyze Website Usage:</strong> We use Google Analytics
              to understand how our website is used and to improve its
              performance.
            </li>
          </ul>

          <h2 className="text-xl font-semibold">Cookies</h2>
          <p>
            PitchAI uses cookies to store information about visitors'
            preferences, to record user-specific information on which pages the
            site visitor accesses or visits, and to personalize or customize our
            web page content based upon visitors' browser type or other
            information that the visitor sends via their browser.
          </p>

          <h2 className="text-xl font-semibold">Data Sharing and Disclosure</h2>
          <p>
            We do not sell, trade, or otherwise transfer to outside parties your
            Personally Identifiable Information (PII). This does not include
            trusted third parties who assist us in operating our website,
            conducting our business, or servicing you, so long as those parties
            agree to keep this information confidential. We may also release
            your information when we believe release is appropriate to comply
            with the law, enforce our site policies, or protect ours or others'
            rights, property, or safety.
          </p>

          <h2 className="text-xl font-semibold">Data Security</h2>
          <p>
            We implement a variety of security measures to maintain the safety
            of your personal information when you enter, submit, or access your
            personal information.
          </p>

          <h2 className="text-xl font-semibold">Your Rights</h2>
          <p>
            As a user, you have the following rights regarding your personal
            data:
          </p>
          <ul className="list-disc list-inside">
            <li>
              <strong>Access:</strong> You have the right to request access to
              your personal data.
            </li>
            <li>
              <strong>Correction:</strong> You can request us to amend your data
              if you believe it is inaccurate or incomplete.
            </li>
            <li>
              <strong>Erasure:</strong> You have the right to request the
              deletion of your data under certain conditions.
            </li>
            <li>
              <strong>Objection:</strong> You can object to the processing of
              your personal data in certain circumstances.
            </li>
          </ul>
          <p>
            To exercise any of these rights, please contact us at{" "}
            <a href="mailto:privacy@pitchai.net" className="text-blue-500">
              privacy@pitchai.net
            </a>
            .
          </p>

          <h2 className="text-xl font-semibold">
            Changes to Our Privacy Policy
          </h2>
          <p>
            PitchAI may update its privacy policy from time to time. We will
            notify you of any changes by posting the new privacy policy on this
            page. You are advised to review this privacy policy periodically for
            any changes.
          </p>

          <h2 className="text-xl font-semibold">Contact Us</h2>
          <p>
            If you have any questions about this privacy policy, please contact
            us at:
          </p>
          <p>
            PitchAI
            <br />
            Email:{" "}
            <a href="mailto:info@pitchai.net" className="text-blue-500">
              info@pitchai.net
            </a>
            <br />
            Address: Raadhuisplein 1, Moerkapelle, 2751AX, Netherlands.
          </p>
          <p>This document was last updated on June 14th 2024.</p>
          <p>
            Thank you for using PitchAI and for trusting us with your
            information.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};
