import React, { useEffect, useRef, useState } from "react";
import Autoplay from "embla-carousel-autoplay";

import { Carousel } from "@mantine/carousel";
export const TechStack = () => {
  const autoplay = useRef(Autoplay({ delay: 2000 }));

  return (
    <div className="my-16 p-2 md:p-0 max-w-2xl mx-auto">
      <h2 className="z-50 text-gray-700 mx-auto text-2xl text-start font-bold  text-center">
        Tech Stack
      </h2>{" "}
      <Carousel
        mx={"auto"}
        slideSize="25%"
        loop
        withIndicators={false}
        className="md:max-w-5xl mt-16 max-w-xs mb-8 md:mb-0 mx-auto grayscale opacity-50 "
        plugins={[autoplay.current]}
      >
        <Carousel.Slide>
          <img
            className="h-8 mx-auto"
            src={process.env.PUBLIC_URL + "/imgs/azure.png"}
          ></img>
        </Carousel.Slide>
        <Carousel.Slide>
          <img
            className="h-8 mx-auto"
            src={process.env.PUBLIC_URL + "/imgs/google.png"}
          ></img>
        </Carousel.Slide>
        <Carousel.Slide>
          <img
            className="h-8 mx-auto"
            src={process.env.PUBLIC_URL + "/imgs/openai.png"}
          ></img>
        </Carousel.Slide>

        <Carousel.Slide>
          <img
            className="h-8 mx-auto "
            src={process.env.PUBLIC_URL + "/imgs/microsoft.png"}
          ></img>
        </Carousel.Slide>
        <Carousel.Slide>
          <img
            className="h-8 mx-auto "
            src={process.env.PUBLIC_URL + "/imgs/react.png"}
          ></img>
        </Carousel.Slide>
        <Carousel.Slide>
          <img
            className="h-8 mx-auto "
            src={process.env.PUBLIC_URL + "/imgs/python.png"}
          ></img>
        </Carousel.Slide>
        {/* <Carousel.Slide>
          <img
            className="h-12 -mt-2  mx-auto"
            src={process.env.PUBLIC_URL + "/imgs/aws.webp"}
          ></img>
        </Carousel.Slide> */}
      </Carousel>
    </div>
  );
};
