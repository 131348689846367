import React from "react";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <div className="">
      <div className="w-full p-8 bg-slate-800 flex-col md:flex-row gap-4 flex justify-around items-center">
        <img className=" h-20" src={process.env.PUBLIC_URL + "/logo.png"}></img>
        <p className="text-xl font-bold text-white">© PitchAI</p>
        <div className=" flex flex-col justify-center items-center gap-4">
          {" "}
          <Link className="cursor-pointer " to="/privacy">
            {" "}
            <p className="text-white underline hover:text-green-400 transition-all duration-200">
              Privacy Policy
            </p>
          </Link>
          <Link className="cursor-pointer " to="/home">
            {" "}
            <p className="text-white underline hover:text-green-400 transition-all duration-200">
              About Us
            </p>
          </Link>
          <Link className="cursor-pointer " to="/solutions">
            {" "}
            <p className="text-white underline hover:text-green-400 transition-all duration-200">
              Solutions
            </p>
          </Link>
          {/* <Link className="cursor-pointer " to="/privacy">
            {" "}
            <p className="text-white underline hover:text-green-400 transition-all duration-200">
              Our Clients
            </p>
          </Link> */}
        </div>
      </div>
    </div>
  );
};
