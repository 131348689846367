import React, { useEffect, useState } from "react";

import { NavBar } from "../NavBar";
import { Element, scroller } from "react-scroll";
import { Link } from "react-router-dom";
import { FaPeopleGroup, FaRocket, FaMoneyBillTrendUp } from "react-icons/fa6";
import { Availability } from "./Availability";
import { AISolutions } from "../AISolutions";
import { Automated } from "../Automated";
import { References } from "../References";
import { TechStack } from "../TechStack";
import { Footer } from "./Footer";

export const Home = (props) => {
  const [chip, setChip] = useState("");

  //Scroll to section
  useEffect(() => {
    const section = props.match.params.section;
    if (section) {
      scroller.scrollTo(section, {
        delay: 400,
        duration: 1000,
        smooth: true,
      });
    }
  });

  function currentlyAvailable() {
    // If between 9 and 21 take a 75% chance of being available, this should pesist every 3 minutes.
    const now = new Date();
    const hour = now.getHours();
    const minute = now.getMinutes();
    const second = now.getSeconds();
    if (hour >= 9 && hour <= 21) {
      if (minute % 3 === 0) {
        return Math.random() < 0.75;
      }
    }
    return false;
  }

  return (
    <div className="bg-slate-50">
      {/* <Hero></Hero> */}
      {/* <div className="h-screen w-screen bg-gradient-to-br from-cyan-600 to-green-400 ">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div> */}

      <div className="md:h-screen grow bg-slate-50 md:flex flex-col justify-between">
        <NavBar></NavBar>{" "}
        <div className="  md:flex md:flex-col justify-center items-center">
          {" "}
          <h1 className="z-50 max-w-5xl mt-8  mx-auto text-2xl md:text-6xl font-bold text-transparent bg-clip-text bg-gradient-to-br from-cyan-600 to-green-400 text-center">
            {"Accelerate intelligent productivity".toUpperCase()}
          </h1>
          <h2 className="z-50 max-w-4xl mx-auto text-xl md:text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-br from-gray-600 to-gray-200 text-center">
            {"With the AI transformation".toUpperCase()}
          </h2>
        </div>
        <div className="p-4 md:p-8 mb-40 md:mb-0 flex justify-center">
          <div className="flex flex-col md:flex-row mx-auto  gap-8 justify-center absolute z-50 text-gray-700">
            <Link to="/home/web" duration={500}>
              <div className="flex gap-2 group cursor-pointer items-center px-4 ">
                <FaPeopleGroup size={24} />
                <h3 className="font-bold">Automate Intelligence</h3>
              </div>
            </Link>

            <Link to="/home/ai" duration={500}>
              <div className="flex group cursor-pointer  gap-2 items-center px-4 ">
                <FaRocket size={24} />
                <h3 className="font-bold ">Boost Productivity</h3>
              </div>
            </Link>

            <Link to="/home/content" duration={500}>
              <div className="flex gap-2 group cursor-pointer items-center px-4 ">
                <FaMoneyBillTrendUp size={24} />
                <h3 className="font-bold">Slash Costs</h3>
              </div>{" "}
            </Link>
          </div>
        </div>{" "}
        <div className="p-2 md:p-0 max-w-2xl mx-auto  items-center justify-center flex flex-col md:flex-row gap-16 justify-around mb-8">
          <div className=" mx-auto items-center justify-center flex flex-col items-start justify-around">
            <div>
              <h2 className="hidden md:block text-gray-500 font-bold text-xs md:text-sm">
                PitchAI develops, builds and facilitates the adoption of AI in
                your company!
              </h2>
              <h2 className="z-50 flex gap-1 md:gap-2 max-w-6xl mx-auto text-lg md:text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-br from-cyan-600 to-green-400 text-center flex-wrap">
                Let's have a<p className="text-gray-500">casual chat </p>on AI's
                <p className="text-gray-500"> potential for your business</p>
              </h2>
            </div>
            <h2 className="text-gray-500  text-sm">
              Our passion is to explore, alongside you, the transformative power
              of AI for your business, free of any obligations.
            </h2>
            <Availability />
          </div>
        </div>
      </div>

      {/* <AIForMyBusiness /> */}
      <Element id="solutions" className="mt-32">
        {" "}
        <AISolutions />
      </Element>
      <References />
      <Automated />
      <TechStack />
      <Footer />
    </div>
  );
};
